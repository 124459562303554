import React from 'react';
import { ButtonTeal, ButtonRed } from "../Buttons"

function PinDetail({ DEBUG, BASE_URL, csrf, toggleSidebar, pin, markers, toggleDeleteConfirmation, showDelete, displayPinForm, displayPinEditForm}) {

    const deletePin = () => {
        if (DEBUG) {
            console.log("Deleting pin " + pin.properties.pk);
        }
        fetch(`${BASE_URL}/pins/${pin.properties.pk}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
        })
        .then(deletePinResponse)
        .catch((error) => {
            console.error(error);
        });
    }

    const deletePinResponse = (response) => {
        if (response.status >= 200 && response.status <= 299) {
            removeMarker(pin.properties.pk);
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
            toggleDeleteConfirmation();
        } else {
            throw Error(response.status + ' ' + response.statusText);
        }
    }

    const removeMarker = (pk) => {
        if (DEBUG) {
            console.log("Deleting marker " + pk);
        }
        const marker = markers.find(obj => obj.pk === pk).marker;
        marker.remove();
    }

    return (
        <>
            <div className="h-full flex flex-col">
                <div id="pin-detail" className="my-auto p-4 flex flex-col justify-center">
                    <div>
                        <button className="p-2 text-teal-700 hover:text-teal-900" onClick={displayPinForm}>
                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" /></svg>
                        </button>
                    </div>
                    <div className="mb-4 text-center block text-2xl text-teal-900 font-extrabold">{pin.properties.name}</div>
                    <div className="mb-4 text-gray-700 text-center font-semibold">{pin.properties.description}</div>
                    {pin.properties.tags && pin.properties.tags.length > 0 && (
                        <div className="mb-4 flex flex-row justify-center text-lg leading-none text-teal-700">
                            {pin.properties.tags.map(tag =>
                                <div className="px-2 py-1 mx-1 text-emerald-100 bg-teal-700 rounded text-md cursor-default select-none font-medium" key={tag}>{tag}</div>
                            )}
                        </div>
                    )}
                    {!showDelete ? (
                        <div className="flex flex-row mt-4 mb-8">
                            <button className="mx-auto text-teal-700 hover:text-teal-900 p-2" title="Edit pin" onClick={displayPinEditForm}>
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>
                            </button>
                            <button className="mx-auto text-teal-700 hover:text-teal-900 p-2" title="Delete pin" onClick={toggleDeleteConfirmation}>
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" /></svg>
                            </button>
                        </div>
                    ) : (
                        <div className="flex flex-row mt-4 mb-8">
                            <ButtonRed outerClass="mx-auto w-26 text-lg leading-5" buttonText="Delete" buttonType="button" onClick={deletePin} />
                            <ButtonTeal outerClass="mx-auto w-26 text-lg leading-5" buttonText="Cancel" buttonType="button" onClick={toggleDeleteConfirmation}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default PinDetail;